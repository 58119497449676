
    // align uk icon to look visually correct in pagination
    .uk-pagination > *.pagination-icon > * {
        padding: 4px 0 5px;
    }


    .rtl .uk-icon {
        transform:rotate(180deg);
    }

